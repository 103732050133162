var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "我的", name: "my" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "作业票清单",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-remove": _vm.handleDelete,
                  "head-add": _vm.headAdd,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayoutMy",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _vm.activeName == "my"
                ? _c("grid-layout", {
                    ref: "gridLayOutMy",
                    attrs: {
                      "table-options": _vm.option,
                      "table-data": _vm.myTableData,
                      "table-loading": _vm.myLoading,
                      "data-total": _vm.myPage.total,
                      "grid-row-btn": _vm.gridRowBtn,
                      page: _vm.myPage,
                    },
                    on: {
                      "grid-row-detail-click": _vm.rowView,
                      "page-current-change": _vm.onLoadMyPage,
                      "page-size-change": _vm.onLoadMyPage,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.businessStatus == "CONFIRMING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        disabled: row.taskId == -1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "sign")
                                        },
                                      },
                                    },
                                    [_vm._v("签署")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "APPROVING" && row.approve
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "approve")
                                        },
                                      },
                                    },
                                    [_vm._v("审批")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "edit")
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "FINISHED" ||
                              row.businessStatus == "CONFIRMED"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "view")
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "FINISHED" ||
                              row.businessStatus == "APPROVING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFlow(row)
                                        },
                                      },
                                    },
                                    [_vm._v("流程图")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "FINISHED" ||
                              row.businessStatus == "APPROVING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleProgress(row)
                                        },
                                      },
                                    },
                                    [_vm._v("流程进度")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDel(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1447607587
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "全部", name: "all" } },
            [
              _vm.activeName == "my"
                ? _c("head-layout", {
                    attrs: {
                      "head-title": "作业票清单",
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-remove": _vm.handleDelete,
                      "head-add": _vm.headAdd,
                    },
                  })
                : _vm._e(),
              _c("grid-head-layout", {
                ref: "gridHeadLayoutAll",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _vm.activeName == "all"
                ? _c("grid-layout", {
                    ref: "gridLayOutAll",
                    attrs: {
                      "table-options": _vm.option,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.loading,
                      "data-total": _vm.page.total,
                      "grid-row-btn": _vm.gridRowBtn,
                      page: _vm.page,
                    },
                    on: {
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                      "grid-row-detail-click": _vm.rowView,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.businessStatus == "CONFIRMING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        disabled: row.taskId == -1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "sign")
                                        },
                                      },
                                    },
                                    [_vm._v("签署")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "APPROVING" && row.approve
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "approve")
                                        },
                                      },
                                    },
                                    [_vm._v("审批")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "edit")
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "FINISHED" ||
                              row.businessStatus == "CONFIRMED"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "view")
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "FINISHED" ||
                              row.businessStatus == "APPROVING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFlow(row)
                                        },
                                      },
                                    },
                                    [_vm._v("流程图")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "FINISHED" ||
                              row.businessStatus == "APPROVING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleProgress(row)
                                        },
                                      },
                                    },
                                    [_vm._v("流程进度")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDel(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2318389225
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.kictectType
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "940px",
                dialogTitle: "作业票类型选择",
                showConfirmBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.kictectType = false
                },
              },
            },
            [_c("template-type", { on: { selectData: _vm.selectKicket } })],
            1
          )
        : _vm._e(),
      _vm.bpmnVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                customClass: "wf-dialog",
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.circulationVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "35%",
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.process.title.processProgressTitle`
                ),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.circulationVisible = false
                },
              },
            },
            [_c("wf-flow", { attrs: { flow: _vm.flow } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }