<template>
  <div class="center">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的" name="my">
        <!--列表label-->
        <head-layout
          head-title="作业票清单"
          :head-btn-options="headBtnOptions"
          @head-remove="handleDelete"
          @head-add="headAdd"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayoutMy"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOutMy"
          :table-options="option"
          :table-data="myTableData"
          v-if="activeName == 'my'"
          @grid-row-detail-click="rowView"
          :table-loading="myLoading"
          :data-total="myPage.total"
          :grid-row-btn="gridRowBtn"
          :page="myPage"
          @page-current-change="onLoadMyPage"
          @page-size-change="onLoadMyPage"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{ row }">
            <el-button
              v-if="row.businessStatus == 'CONFIRMING'"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'sign')"
              :disabled="row.taskId == -1"
              >签署</el-button
            >
            <el-button
              v-if="row.businessStatus == 'APPROVING' && row.approve"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'approve')"
              >审批</el-button
            >
            <el-button
              v-if="row.businessStatus == 'PREPARE'"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'edit')"
              >编辑</el-button
            >
            <el-button
              v-if="
                row.businessStatus == 'FINISHED' ||
                row.businessStatus == 'CONFIRMED'
              "
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'view')"
              >查看</el-button
            >
            <el-button v-if="
                row.businessStatus == 'FINISHED' ||
                row.businessStatus == 'APPROVING'
              " style="margin: 0 3px" type="text" size="small" @click="handleFlow(row)"
              >流程图</el-button
            >
            <el-button v-if="
                row.businessStatus == 'FINISHED' ||
                row.businessStatus == 'APPROVING'" style="margin: 0 3px" type="text" size="small" @click="handleProgress(row)"
              >流程进度</el-button
            >
            <el-button
              v-if="row.businessStatus == 'PREPARE'"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowDel(row)"
              >删除</el-button
            >
          </template>
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane label="全部" name="all">
        <!--列表label-->
        <head-layout
          v-if="activeName == 'my'"
          head-title="作业票清单"
          :head-btn-options="headBtnOptions"
          @head-remove="handleDelete"
          @head-add="headAdd"
        ></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout
          ref="gridHeadLayoutAll"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOutAll"
          v-if="activeName == 'all'"
          :table-options="option"
          :table-data="tableData"
          :table-loading="loading"
          :data-total="page.total"
          :grid-row-btn="gridRowBtn"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @grid-row-detail-click="rowView"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{ row }">
            <el-button
              v-if="row.businessStatus == 'CONFIRMING'"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'sign')"
              :disabled="row.taskId == -1"
              >签署</el-button
            >
            <el-button
              v-if="row.businessStatus == 'APPROVING' && row.approve"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'approve')"
              >审批</el-button
            >
            <el-button
              v-if="row.businessStatus == 'PREPARE'"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'edit')"
              >编辑</el-button
            >
            <el-button
              v-if="
                row.businessStatus == 'FINISHED' ||
                row.businessStatus == 'CONFIRMED'
              "
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="edit(row, 'view')"
              >查看</el-button
            >
            <el-button v-if="
                row.businessStatus == 'FINISHED' ||
                row.businessStatus == 'APPROVING'"  style="margin: 0 3px" type="text" size="small" @click="handleFlow(row)"
              >流程图</el-button
            >
            <el-button v-if="
                row.businessStatus == 'FINISHED' ||
                row.businessStatus == 'APPROVING'" style="margin: 0 3px" type="text" size="small" @click="handleProgress(row)"
              >流程进度</el-button
            >
            <el-button
              v-if="row.businessStatus == 'PREPARE'"
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="rowDel(row)"
              >删除</el-button
            >
          </template>
        </grid-layout>
      </el-tab-pane>
    </el-tabs>
<!--    <el-dialog title="作业票类型选择" :visible.sync="kictectType" width="940px">-->
<!--      <div class="kicket-content">-->
<!--        <div-->
<!--          v-for="(item, index) in kictectTypeList"-->
<!--          :key="index"-->
<!--          class="kicket"-->
<!--          @click="selectKicket(item)"-->
<!--        >-->
<!--          <img :src="item.kictectIcon" alt="" />-->
<!--          <span>{{ item.dictValue }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="kictectType = false">{{-->
<!--          $t("cip.cmn.btn.celBtn")-->
<!--        }}</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
    <common-dialog
      v-if="kictectType"
      width="940px"
      dialogTitle="作业票类型选择"
      :showConfirmBtn="false"
      @cancel="kictectType = false"
    >
      <template-type @selectData="selectKicket"></template-type>
    </common-dialog>
    <common-dialog
      v-if="bpmnVisible"
      width="70%"
      customClass="wf-dialog"
      :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)"
      :showBtn="false"
      @cancel="bpmnVisible = false"
    >
      <wf-design
        ref="bpmn"
        style="height: 60vh"
        :options="bpmnOption"
      ></wf-design>
    </common-dialog>

    <!-- 流程进度弹框 -->
    <common-dialog
      v-if="circulationVisible"
      width="35%"
      :dialogTitle="$t(`cip.plat.wfOps.process.title.processProgressTitle`)"
      :showBtn="false"
      @cancel="circulationVisible = false"
    >
      <wf-flow :flow="flow"></wf-flow>
    </common-dialog>
  </div>
</template>
<script>
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {detail, getTaskUser} from "@/api/plugin/workflow/process";
import {
  ticketInventoryList,
  ticketType,
  ticketInventoryType,
  ticketInventoryMyPage,
  removeWorkTicket,
  removeTask,
  ticketInventoryDetail,
} from "@/api/workTicket";
import { mapGetters } from "vuex";
import CommonDialog from "@/components/CommonDialog";
import TemplateType from '@/views/business/jobSlip/V1/jobSlipInventory/components/templateType';
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
export default {
  data() {
    return {
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      myLoading: true,
      myPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      myTableData: [],
      selectionList: [],
      activeName: "my",
      kictectType: false,
      kictectIcon: [
        require("@/assets/images/kicket/gkzy.jpg"),
        require("@/assets/images/kicket/dtzy.jpg"),
        require("@/assets/images/kicket/dhzy.jpg"),
        require("@/assets/images/kicket/dzzy.jpg"),
        require("@/assets/images/kicket/sxkjzy.jpg"),
        require("@/assets/images/kicket/lsydzy.jpg"),
        require("@/assets/images/kicket/dlzy.jpg"),
        require("@/assets/images/kicket/tdzy.jpg"),
        require("@/assets/images/kicket/sdzy.jpg"),
        require("@/assets/images/kicket/mbcdzy.jpg"),
      ],
      kictectTypeList: [],
      searchColumns: [
        {
          prop: "code",
          type: "input",
          span: 3,
          placeholder: "请输入作业票编号",
        },
        {
          prop: "type",
          span: 3,
          placeholder: "请选择作业票类型",
          type: "select",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
        {
          prop: "createUserName",
          type: "input",
          span: 3,
          placeholder: "请输入申请人",
        },
        {
          prop: "workSupervisor",
          type: "input",
          span: 3,
          placeholder: "请输入负责人",
        },
        {
          prop: "businessStatus",
          type: "select",
          span: 3,
          placeholder: "请选择状态",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=wpt_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
      ],
      bpmnVisible: false,
      bpmnOption: {},
      circulationVisible: false,
    };
  },
  components: { HeadLayout, GridLayout, CommonDialog, WfFlow, TemplateType },
  mixins: [exForm],

  computed: {
    ...mapGetters(["userInfo","language"]),
    headBtnOptions() {
      return [
        {
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          btnOptType: "add",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-remove",
          btnOptType: "remove",
        },
      ];
    },
    gridRowBtn() {
      return [];
    },
    option() {
      return {
        linklabel: "code",
        selectable: (row, index) => {
          return row.businessStatus == "PREPARE";
        },
        column: [
          {
            label: "作业票编号",
            prop: "code",
            align: "left",
            overHidden: true,
          },
          {
            label: "作业票类型",
            type: "select",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            prop: "type",
            overHidden: true,
          },
          {
            label: "作业内容",
            prop: "workContent",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业实施时间",
            prop: "workExecutionTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业负责人",
            prop: "workSupervisor",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "businessStatus",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=wpt_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  created() {
    this.onLoadMyPage(this.myPage);
    this.getTicketList();
  },
  mounted() {
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-refresh") {
        if (this.activeName == "my") {
          this.myPage.currentPage = 1;
          this.onLoadMyPage(this.myPage);
        } else if (this.activeName == "all") {
          this.page.currentPage = 1;
          this.onLoad(this.page);
        }
        localStorage.setItem("sinoma-refresh", JSON.stringify(false)); //变成false , 可以来回监听
      }
    });
  },

  methods: {
     // 查看流程进度
     handleProgress(row) {
      const { processInsId } = row
      this.getTaskDetail(null, processInsId).then(() => {
        this.waiting = false // 关闭骨架屏
      })
      this.circulationVisible = true
    },
    // 查看流程图
    handleFlow(row) {
      const { processInsId } = row;
      detail({ processInsId: processInsId }).then((res) => {
        const { process, flow } = res.data.data;

        this.bpmnOption = {
          mode: "view",
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language,
        };

        this.bpmnVisible = true;
      });
    },
    handleClick(val) {
      if (val.name == "all") {
        this.$refs.gridHeadLayoutAll.searchForm = {};
        this.page.currentPage = 1;
        this.onLoad(this.page);
      } else if (val.name == "my") {
        this.$refs.gridHeadLayoutMy.searchForm = {};
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage);
      }
    },
    getTicketList() {
      ticketType("work_permit_type").then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((item) => {
            if (item.dictKey == "gkzy") {
              item["kictectIcon"] = this.kictectIcon[0];
            } else if (item.dictKey == "dtzy") {
              item["kictectIcon"] = this.kictectIcon[1];
            } else if (item.dictKey == "dhzy") {
              item["kictectIcon"] = this.kictectIcon[2];
            } else if (item.dictKey == "dzzy") {
              item["kictectIcon"] = this.kictectIcon[3];
            } else if (item.dictKey == "yxkj") {
              item["kictectIcon"] = this.kictectIcon[4];
            } else if (item.dictKey == "lsyd") {
              item["kictectIcon"] = this.kictectIcon[5];
            } else if (item.dictKey == "dlzy") {
              item["kictectIcon"] = this.kictectIcon[6];
            } else if (item.dictKey == "mbcd") {
              item["kictectIcon"] = this.kictectIcon[7];
            } else if (item.dictKey == "bpzy") {
              item["kictectIcon"] = this.kictectIcon[8];
            } else if (item.dictKey == "qtzy") {
              item["kictectIcon"] = this.kictectIcon[9];
            }
          });
          this.kictectTypeList = res.data.data;
        }
      });
    },
    headAdd() {
      this.kictectType = true;
    },
    selectKicket(data) {
      this.kictectType = false;
      this.$router.push({
        path: `/jobSlip/jobSlipInventory/add`,
        query: {
          type: "add",
          templateId: data.id,
        },
      });
    },
    edit(row, type) {
      if (type == "sign") {
        this.$router.push({
          path: `/jobSlip/jobSlipInventory/sign`,
          query: {
            type: type,
            taskId: row.taskId,
          },
        });
      } else if (type == "approve") {
        const { processInsId } = row;
        detail({ processInsId }).then((res) => {
          const { process } = res.data.data;
          const { id, taskId, processInstanceId, processId, processDefKey } =
            process;
          let param = Buffer.from(
            JSON.stringify({
              processId: id,
              taskId,
              processInsId: processInstanceId || processId,
              processDefKey,
            })
          ).toString("base64");
          //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
          let sUrl =
            window.location.origin +
            "#/process/external/iframe/detail?p=" +
            param;
          window.open(sUrl, "_blank");
        });
      } else if (type == "view") {
        this.$router.push({
          path: `/jobSlip/jobSlipInventory/view`,
          query: {
            type: type,
            id: row.id,
          },
        });
      } else {
        this.$router.push({
          path: `/jobSlip/jobSlipInventory/edit`,
          query: {
            type: type,
            id: row.id,
          },
        });
      }
    },
    rowView(row) {
      this.$router.push({
        path: `/jobSlip/jobSlipInventory/view`,
        query: {
          type: "view",
          id: row.id,
        },
      });
    },
    // 列表查询
    gridHeadSearch(searchForm) {
      if (this.activeName == "my") {
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage, searchForm);
      } else if (this.activeName == "all") {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      }
    },
    // 查询重置
    gridHeadEmpty(searchForm) {
      if (this.activeName == "my") {
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage, searchForm);
      } else if (this.activeName == "all") {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      }
    },
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        if (row.taskId !== -1) {
          removeTask(row.taskId).then((res) => {
            if (res.data.code == 200) {
              if (this.activeName == "my") {
                this.onLoadMyPage(this.myPage);
              } else if (this.activeName == "all") {
                this.onLoad(this.page);
              }
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess"),
              });
            }
          });
        } else {
          removeWorkTicket(row.id).then((res) => {
            if (res.data.code == 200) {
              if (this.activeName == "my") {
                this.onLoadMyPage(this.myPage);
              } else if (this.activeName == "all") {
                this.onLoad(this.page);
              }
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess"),
              });
            }
          });
        }
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      let taskIds = [];
      let ticketIds = [];
      this.selectionList.forEach((item) => {
        if (item.taskId == -1) {
          ticketIds.push(item.id);
        } else {
          taskIds.push(item.taskId);
        }
      });
      let taskIdStr = taskIds.join(",");
      let ticketStr = ticketIds.join(",");
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(async () => {
        if (ticketStr) await removeWorkTicket(ticketStr);
        if (taskIdStr) await removeTask(taskIdStr);
        if (this.activeName == "my") {
          this.onLoadMyPage(this.myPage);
        } else if (this.activeName == "all") {
          this.onLoad(this.page);
        }
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
      });
    },
    onLoad(page, params = {}) {
      let that = this;
      that.page = page;
      that.loading = true;
      ticketInventoryList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, { organizationId: that.userInfo.dept_id })
      ).then(async (res) => {
        const data = res.data.data;
        that.page.total = data.total;
        that.$nextTick(() => {
          that.$refs.gridLayOutAll.page.total = data.total;
        });
        that.tableData = data.records;
        try {
          await that.processTableData(that.myTableData);
        } catch (error) {
          // 处理 processTableData 函数可能抛出的错误
          console.error("Error in processTableData:", error);
        }
        that.loading = false;
      });
    },
    onLoadMyPage(page, params = {}) {
      let that = this;
      that.myPage = page;
      that.myLoading = true;
      ticketInventoryMyPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {
          userId: that.userInfo.user_id,
          organizationId: that.userInfo.dept_id,
        })
      ).then(async (res) => {
        const data = res.data.data;
        that.myPage.total = data.total;
        that.$nextTick(() => {
          that.$refs.gridLayOutMy.page.total = data.total;
        });
        that.myTableData = data.records;
        try {
          await that.processTableData(that.myTableData);
        } catch (error){
          // 处理 processTableData 函数可能抛出的错误
          console.error("Error in processTableData:", error);
        }
        that.myLoading = false;
      });
    },

    async processTableData(data) {
      let that = this;
      let taskId = null;
      for (const e of data) {
        if (e.businessStatus === "APPROVING") {
          // 当前流程审批选择角色时，会让审批人员与发起人同部门，如果后续变更那么该条件需变化
          if (e.createDept === this.userInfo.dept_id) {
            if (e.processInsId && e.processInsId !== "") {
              try {
                const res = await this.getTaskDetail(taskId, e.processInsId);
                if (res.process.assignee.includes(this.userInfo.id)) {
                  that.$set(e, "approve", true);
                }
              } catch (error) {
                // 处理 getTaskDetail 函数可能抛出的错误
                console.error("Error in getTaskDetail:", error);
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}
::v-deep .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}

::v-deep .el-dialog__body {
  padding: 20px;
}

.kicket-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .kicket {
    width: 140px;
    text-align: center;
    margin: 20px;
  }
}
::v-deep .bjs-powered-by {
  display: none;
}
</style>
